<template>
  <div>
    <div class="record">
      <el-row class="lianxi" :gutter="10">
        <el-col :span="10" :xs="24">
          <div class="left">
            <div class="left-dw">
              <el-row :gutter="10">
                <el-col :span="3" :xs="24">
                  <p
                  class="title"
                  >
                    {{ $t("寺院账户") }}
                  </p>
                </el-col>
                <el-col :span="7" :xs="24"
                  ><p>{{ $t("台湾地区") }}</p>
                  <p>{{ $t("新光銀行: 銀行代碼103") }}</p>
                  <p>{{ $t("帳號: 0578-10-8889538") }}</p>
                  <p>{{ $t("戶名: 台湾摩啊文化有限公司") }}</p></el-col
                >
                <el-col :span="7" :xs="24">
                  <p>{{ $t("新加坡地区") }}</p>
                  <p>{{ $t("United Overseas Bank 大华银行") }}</p>
                  <p>
                    {{ $t("账号: 373 323 7234") }}
                  </p>
                  <p>{{ $t("户名：zhuangchangsai") }}</p>
                </el-col>
                <el-col :span="7" :xs="24">
                  <p>{{ $t("马来西亚地区") }}</p>
                  <p>{{ $t("扫码进行TNG支付") }}</p>
                 <el-image style="width: 80px; height: 80px;margin-top: 5px;" :src="require('@/assets/home/malai.png')" :preview-src-list="[require('@/assets/home/malai.png')]" :fit="fit"></el-image>
                  <p>{{ $t("TNG：+60 186643583") }}</p>
                </el-col>
              </el-row>
              <p >{{ $t("其他地区善信发心，可联系客服了解拨款方式") }}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="4" :xs="24" v-if="!mobile">
          <div class="conter">
            <el-image :src="require('@/assets/home/logoft.png')" lazy />
          </div>
        </el-col>
        <el-col :span="10" :xs="24">
          <div class="right">
            <div class="right-dw">
              <el-row :gutter="20">
                <el-col :span="10" :xs="24">
                  <p>{{ $t("邮编：315012") }}</p>
                  <p>{{ $t("微信客服：17675702568") }}</p>
                  <p>{{ $t("邮箱：NBkanjingsi@163.com") }}</p>
                  <p>{{ $t("电话：0574-87463108（客堂）") }}</p>
                  <p @click="WindowOpens('https://www.nbkjs.com')">{{ $t("官网网址www.nbkjs.com") }}</p>
                </el-col>
                <el-col :span="6" :xs="12" style="text-align: center">
                  <div style="margin-bottom: 20px">
                    <el-image
                      :src="require('@/assets/home/whats.png')"
                      style="width: 100px; height: 100px"
                    />
                    <span style="display: block; text-align: center"
                      >whatsapp</span
                    >
                  </div>
                  <div>
                    <a
                      class="a-btn2"
                      href="https://diversion.salesmartly.com/split/whatsapp/er615a"
                      style="background-color: #25d366"
                    >
                      <el-image
                        :src="require('@/assets/home/whatsappicon.png')"
                        style="
                          vertical-align: middle;
                          width: 20px;
                          height: 20px;
                        "
                      />
                    </a>
                  </div>
                </el-col>
                <el-col :span="6" :xs="12">
                  <div style="margin-bottom: 20px">
                    <el-image
                      :src="require('@/assets/home/wxsfx.png')"
                      style="width: 100px; height: 100px"
                    />
                    <span style="display: block; text-align: center"
                      >微信二维码</span
                    >
                  </div>
                  <div>
                    <a
                      class="a-btn2"
                      @click="
                        WindowOpens(
                          'https://www.facebook.com/profile.php?id=61564820916130'
                        )
                      "
                    >
                      <el-image
                        :src="require('@/assets/home/lian.png')"
                        style="
                          vertical-align: middle;
                          width: 20px;
                          height: 20px;
                        "
                      />
                    </a>
                  </div>
                </el-col>
              </el-row>
              <!-- <div style="margin-top: 20px">
                <el-row :gutter="10">
                  <el-col :span="6" :xs="12" :offset="mobile ? 0 :10">
                    <a
                      class="a-btn2"
                      href="https://diversion.salesmartly.com/split/whatsapp/er615a"
                      style="background-color: #25d366"
                    >
                      <el-image
                        :src="require('@/assets/home/whatsappicon.png')"
                        style="
                          vertical-align: middle;
                          width: 20px;
                          height: 20px;
                        "
                      />
                    </a>
                  </el-col>
                  <el-col :span="6" :xs="12">
                    <a
                      class="a-btn2"
                      @click="
                        WindowOpens(
                          'https://www.facebook.com/profile.php?id=61564820916130'
                        )
                      "
                    >
                      <el-image
                        :src="require('@/assets/home/lian.png')"
                        style="
                          vertical-align: middle;
                          width: 20px;
                          height: 20px;
                        "
                      />
                    </a>
                  </el-col>
                </el-row>
              </div> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <p style="text-align: center; padding: 10px">
      {{
        $t(
          "互联网宗教信息服务许可证：浙（2022）0000329 版权声明： 2022-2025 看经寺"
        )
      }}
    </p>
    <p style="text-align: center; padding: 10px">
      {{ $t("（国家宗教事务局监制）") }}
    </p>
  </div>
</template>

<script>
import { deviceJudgment } from "@/utils/index.js";

export default {
  name: "WebsiteEnd",
  components: {},
  data() {
    return {
      mobile: deviceJudgment(),
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    WindowOpens(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
